import Share from './Share'

function Stats(props) {
    const { stats } = props

    const statNames = {
        gamesPlayed: 'Games Played',
        winRate: 'Win Rate',
        currentStreak: 'Current Streak',
        bestStreak: 'Best Streak'
    }

    const barStyles = {
        bars: {},
        numbers: {}
    }

    const distribution = JSON.parse(stats.distribution)

    function getBarHeights() {
        let coefficient = 0
        let highest = 0

        Object.keys(distribution).map(key => {
            if (distribution[key] > highest) return (highest = distribution[key])
        })

        coefficient = 100 / highest

        Object.keys(distribution).map(tryCount => {
            const height = Math.floor(distribution[tryCount] * coefficient) || 2

            barStyles['bars'][tryCount] = {
                height: `${height}px`,
                bottom: `-${100 - height}px`
            }

            barStyles['numbers'][tryCount] = {
                bottom: `${height + 26}px`
            }
        })
    }

    getBarHeights()

    return (
        <div className='stats'>
            <h1> Statistics </h1>
            <div className='record'>
                {Object.keys(stats).map((statName, index = 0) => {
                    const ignore = [
                        'gamesWon',
                        'distribution',
                        'currentGame',
                        'currentResults',
                        'gameIsOver',
                        'id',
                        'version'
                    ]
                    if (ignore.includes(statName)) return null
                    return (
                        <div key={index} className='stat'>
                            <div key={`${statName}-value`} className={`value ${statName}`}>
                                {' '}
                                <h1> {stats[statName]} </h1>{' '}
                            </div>
                            <div key={statName} className={statName}>
                                {' '}
                                {statNames[statName]}{' '}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='distribution'>
                <div className='bars'>
                    {Object.keys(distribution).map(tryCount => (
                        <div key={tryCount} className={`count-${tryCount}`}>
                            <div
                                key={`${tryCount}-number`}
                                className='bar-number'
                                style={barStyles.numbers[tryCount]}
                            >
                                {' '}
                                {distribution[tryCount] !== 0 && distribution[tryCount]}{' '}
                            </div>
                            <div
                                key={`${tryCount}-bar`}
                                className='bar'
                                style={barStyles.bars[tryCount]}
                            ></div>
                        </div>
                    ))}
                </div>
                <div className='division'></div>
                <div className='titles'>
                    {Object.keys(distribution).map(tryCount => (
                        <div key={tryCount} className='title'>
                            {' '}
                            {tryCount}{' '}
                        </div>
                    ))}
                </div>
            </div>
            <p> Number of Tries </p>
            {JSON.parse(stats.gameIsOver) && (
                <Share
                    id={stats.id}
                    results={JSON.parse(stats.currentResults)}
                    handleNotification={props.handleNotification}
                />
            )}
        </div>
    )
}

export default Stats
