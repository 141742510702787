import { useState, useEffect } from 'react'
import Navbar from './sections/Navbar'
import Main from './sections/Main'
import Popup from './sections/Popup'
import Stats from './sections/popups/Stats'
import About from './sections/popups/About'
import Instructions from './sections/popups/Instructions'

const distribution = {}
;[...Array(7).keys()].map(key => (distribution[key + 1] = 0))

const defaultStats = {
    version: '1.1',
    gamesPlayed: 0,
    gamesWon: 0,
    winRate: 0,
    currentStreak: 0,
    bestStreak: 0,
    distribution: JSON.stringify(distribution),
    currentGame: null,
    currentResults: [],
    gameIsOver: false,
    id: null
}

function App() {
    if (
        (localStorage.length && !localStorage.version) ||
        (localStorage.length && localStorage.version !== defaultStats.version)
    ) {
        Object.keys(defaultStats).map(key => localStorage.setItem(key, defaultStats[key]))
    }
    const stats = !localStorage.length ? defaultStats : localStorage
    const [showStats, setShowStats] = useState(false)
    const [showAbout, setShowAbout] = useState(false)
    const [showInstructions, setShowInstructions] = useState(false)
    const [notification, setNotification] = useState({})
    const [showNotification, setShowNotification] = useState(false)

    function handleNotification(message, color) {
        let noti = notification
        noti.message = message
        noti.color = color
        setNotification(noti)
        setShowNotification(true)
    }

    useEffect(() => {
        if (!showNotification) return
        setTimeout(() => {
            setShowNotification(false)
        }, 4500)
    }, [showNotification])

    return (
        <main className='hardle'>
            <Navbar
                showAbout={setShowAbout}
                showInstructions={setShowInstructions}
                showStats={setShowStats}
            />
            <Main stats={stats} setShowStats={setShowStats} handleNotification={handleNotification} />
            <Popup
                showPopup={showStats}
                setShowPopup={setShowStats}
                content={<Stats stats={stats} handleNotification={handleNotification} />}
            />
            <Popup showPopup={showAbout} setShowPopup={setShowAbout} content={<About />} />
            <Popup
                showPopup={showInstructions}
                setShowPopup={setShowInstructions}
                content={<Instructions />}
            />

            {showNotification && (
                <div className={`notification ${notification.color}`}> {notification.message} </div>
            )}
        </main>
    )
}

export default App
