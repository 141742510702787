import React, { useEffect, useState } from 'react'
import Keyboard from './Keyboard'

// Define min and max amount of allowed letters in guess [min, max]
const guessLimits = [3, 7]
const maxGuesses = 7
let guessCount = 1
let checkingWord = false

function Main(props) {
    const [guessLetters, changeWords] = useState([['']])
    const [keyboardColors, setKeyboardLetters] = useState({})
    const { stats } = props
    const { currentGame } = stats

    let guessIndex = guessCount - 1
    let letterCount = guessLetters[guessIndex][0] === '' ? 0 : guessLetters[guessIndex].length

    async function checkWord(word) {
        checkingWord = true
        const url = `https://api.hardle.app/check-word?word=${word.map(letter => letter.toLowerCase())}`
        const result = await fetch(url).then(result => result.json())

        if (result.accuracy !== 'invalidWord') {
            // Make letter gray on keybaord
            let keyColors = keyboardColors
            word.map((letter, index) => {
                if (!keyColors[letter])
                    keyColors[letter] = result.accuracy[index] === 0 ? 'isGray' : 'isYellow'
            })

            setKeyboardLetters(keyColors)

            // Save guess word to localStorage
            if (stats.currentResults.length === 0) {
                let results = []
                results.push(result.accuracy)
                stats.currentResults = JSON.stringify(results)
            } else {
                let results = JSON.parse(stats.currentResults)
                results.push(result.accuracy)
                stats.currentResults = JSON.stringify(results)
            }
        }

        checkingWord = false
        return result
    }

    async function handleEnter() {
        if (JSON.parse(stats.gameIsOver) === true) return

        if (guessLetters[guessIndex].length === 1) return
        if (guessLetters[guessIndex].length < guessLimits[0]) {
            let message = `Minimum amount of letters is ${guessLimits[0]}`
            props.handleNotification(message, 'red')
            return
        }

        const accuracy = await checkWord(guessLetters[guessIndex])
        if (accuracy.accuracy === 'invalidWord') {
            let message = 'Not in word list'
            props.handleNotification(message, 'red')
            return
        }

        // Check if guess is correct
        let isCorrect = false
        let results = accuracy.accuracy.map(num => {
            return num === 3 ? 'correct' : 'incorrect'
        })
        if (!results.includes('incorrect')) isCorrect = true

        changeColors(accuracy.accuracy, guessIndex)
        const words = [...guessLetters]
        stats.currentGame = JSON.stringify(guessLetters)
        changeWords(words)
        saveStats(stats)

        if (isCorrect) return winGame()
        if (guessCount === maxGuesses) return loseGame()
        words.push([''])
        guessCount++
    }

    function handleBackspace() {
        if (JSON.parse(stats.gameIsOver) === true) return
        if (letterCount === 0) return
        let words = [...guessLetters]

        if (letterCount === 1) {
            words[guessIndex][0] = ''
            changeWords(words)
        } else {
            words[guessIndex].pop()
            changeWords(words)
        }

        letterCount = letterCount - 1
    }

    function handleLetter(key) {
        if (JSON.parse(stats.gameIsOver) === true) return

        let words = [...guessLetters]

        if (letterCount === guessLimits[1]) return
        if (letterCount === 0) {
            words[guessIndex][0] = key
            changeWords(words)
        } else {
            words[guessIndex].push(key)
            changeWords(words)
        }

        letterCount++
    }

    function handleKeyDown(event) {
        if (JSON.parse(stats.gameIsOver) === true || event.ctrlKey || checkingWord) return
        if (event.which >= 65 && event.which <= 90) handleLetter(event.key, guessIndex)
        if (event.which === 13) handleEnter()
        if (event.which === 8) handleBackspace()
    }

    function setKeyColor(letter) {
        return keyboardColors[letter] ? keyboardColors[letter] : ''
    }

    function winGame() {
        // TODO: Add timer and share button to stats on game end
        // TODO: Expand on these win messages (randomly choose from set of responses)
        let message = 'Correct!'
        const stats = props.stats
        const distribution = JSON.parse(stats.distribution)
        stats.gamesPlayed++
        stats.gamesWon++
        stats.currentStreak++
        distribution[guessCount]++
        stats.distribution = JSON.stringify(distribution)

        if (parseInt(stats.bestStreak) < stats.currentStreak) stats.bestStreak = stats.currentStreak
        stats.winRate = getWinRate(stats.gamesWon, stats.gamesPlayed)

        stats.gameIsOver = JSON.stringify(true)

        props.handleNotification(message, 'green')
        saveStats(stats)
        setTimeout(() => props.setShowStats(true), 2000)
    }

    function loseGame() {
        let correctAnswer

        async function getAnswer() {
            const url = `https://api.hardle.app/get-word`
            let response = await fetch(url)
            response = await response.json()
            return response
        }

        getAnswer().then(result => {
            correctAnswer = result.answer
            props.handleNotification(correctAnswer.toUpperCase(), 'red')
        })

        stats.gamesPlayed++
        stats.currentStreak = 0
        stats.winRate = getWinRate(stats.gamesWon, stats.gamesPlayed)
        stats.gameIsOver = JSON.stringify(true)

        saveStats(stats)
        setTimeout(() => props.setShowStats(true), 2000)
    }

    function getWinRate(gamesWon, gamesPlayed) {
        return `${Math.floor((gamesWon / gamesPlayed) * 100)}%`
    }

    function changeColors(results, guessIndex) {
        results.map((result, index) => {
            return document
                .querySelector(`.guessRow.index-${guessIndex} .index-${index}`)
                .classList.add(`color-${result}`)
        })
    }

    function saveStats(stats) {
        Object.keys(stats).map(stat => {
            return localStorage.setItem(stat, stats[stat])
        })
        // if (!stats.id) console.log('stats.id is null... Stats:', stats)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    })

    useEffect(() => {
        async function getId() {
            const url = `https://api.hardle.app/get-id`
            let response = await fetch(url)
            response = await response.json()
            return response
        }

        // First Game
        if (currentGame === null || currentGame === JSON.stringify(guessLetters)) {
            getId().then(result => (stats.id = JSON.stringify(result)))
            return
        }

        // New Game
        // if ()
        getId().then(result => {
            if (result.id && result.id !== JSON.parse(stats.id).id) {
                stats.gameIsOver = JSON.stringify(false)
                stats.currentGame = JSON.stringify(null)
                stats.currentResults = JSON.stringify([])
                stats.gameIsOver = JSON.stringify(false)
                stats.id = JSON.stringify(result)
                guessCount = 1
                guessIndex = 0
                letterCount = guessLetters[guessIndex][0] === '' ? 0 : guessLetters[guessIndex].length
                changeWords([['']])
            } else {
                // Recover ongoing game from localStorage
                let savedResults = JSON.parse(stats.currentResults)
                let game = JSON.parse(currentGame)
                let keyColors = {}

                if (JSON.parse(stats.gameIsOver) === false) game.push([''])
                changeWords(game)
                guessCount = game.length
                guessIndex = guessCount - 1

                setTimeout(() => {
                    savedResults.map((result, guessIndex) => {
                        changeColors(result, guessIndex)
                    })

                    JSON.parse(currentGame).map((word, wordIndex) => {
                        word.map((letter, letterIndex) => {
                            if (!keyColors[letter])
                                keyColors[letter] =
                                    savedResults[wordIndex][letterIndex] === 0 ? 'isGray' : 'isYellow'
                        })
                    })

                    setKeyboardLetters(keyColors)
                    // 10 Timeout because Safari mobile
                }, 10)
            }
        })
    }, [])

    return (
        <>
            <div className='main'>
                <div className='divisions'>
                    {Array.from(Array(maxGuesses).keys()).map(i => (
                        <div key={i} className='line' />
                    ))}
                </div>
                <div className='guessColumn'>
                    {guessLetters.map((row, rowCount) => {
                        let className = `guessRow index-${rowCount}`
                        return (
                            <div key={rowCount} className={className}>
                                {row.map((letter, letterCount) => {
                                    let className = `guessBox index-${letterCount}`
                                    const letterBox = (
                                        <div key={letterCount} className={className}>
                                            {' '}
                                            {letter}{' '}
                                        </div>
                                    )
                                    letterCount++
                                    return letterBox
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>

            <Keyboard
                handleEnter={handleEnter}
                handleBackspace={handleBackspace}
                handleLetter={handleLetter}
                isGray={setKeyColor}
            />
        </>
    )
}

export default Main
