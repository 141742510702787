import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

function About() {
    return (
        <>
            <h1> About </h1>
            <ul className="about">
                <li> <b>Author</b>: Daniel DeLima </li>
                <li>
                    <b>Contact</b>: DanielDTech@gmail.com
                    <FontAwesomeIcon icon={faCopy} onClick={() => navigator.clipboard.writeText('danieldtech@gmail.com')} />
                </li>
            </ul>
        </>
    )
}

export default About;