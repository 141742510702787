import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';

function Keyboard(props) {
    let letters = ['qwertyuiop', 'asdfghjkl', 'zxcvbnm'];

    let rows = letters.map(row => row.split(''));
    rows[2].unshift('Enter');
    rows[2].push(<FontAwesomeIcon icon={faDeleteLeft} size='2x' className="backspace"/>);

    function handleClick(key) {
        if (key === 'Enter') return props.handleEnter();
        if (key.props) return props.handleBackspace();
        props.handleLetter(key);
    }

    return (
        <div className="keyboard">
            {rows.map((row, index=0) => (
                <div key={row} className={`row row-${index}`}> 
                    {row.map(letter =>
                        <button
                            key={letter}
                            className={`letter ${props.isGray(letter)}`}
                            onClick={() => {handleClick(letter)}}>
                            {letter}
                        </button>
                    )}
                </div>
            ))}
        </div>
    )
}

export default Keyboard;