import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

function Navbar(props) {
    return (
        <div className='navbar'>
            <div className='left-section'>
                <div className='info' onClick={() => props.showAbout(true)}>
                    <div className='dot'></div>
                    <div className='line'></div>
                    <div className='bar'></div>
                    <div className='base'></div>
                </div>
            </div>
            <h1> Hardle </h1>
            <div className='right-section'>
                <FontAwesomeIcon icon={faQuestionCircle} size='3x' onClick={() => props.showInstructions(true)} />
                <FontAwesomeIcon icon={faChartLine} size='3x' onClick={() => props.showStats(true)}/>
            </div>
            <div className='division'></div>
        </div>
    )
}

export default Navbar;
