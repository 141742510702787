function Instructions() {
    return (
        <div className="instructions">
            <h1> How to Play </h1>
            <p> You have <b>7 tries</b> to guess the <b>3-7 letter word</b>. There is a new word at 5 a.m. and 5 p.m. EST every day. </p>
            
            <div className="divison"></div>

            <div className="guessRow row-1">
                <div className="guessBox bg-gray"> F </div>
                <div className="guessBox bg-yellow"> A </div>
                <div className="guessBox bg-gray"> N </div>
            </div>

            <p><span className="gray">Gray: </span>
                The letter <b>is not</b> in the word.
            </p>
            <p><span className="yellow">Yellow: </span>
                The letter <b>is</b> in the word.
            </p>

            <div className="divison"></div>

            <div className="guessRow row-2">
                <div className="guessBox bg-gray"> S </div>
                <div className="guessBox bg-blue"> H </div>
                <div className="guessBox bg-green"> A </div>
                <div className="guessBox bg-blue"> R </div>
                <div className="guessBox bg-gray"> P </div>
            </div>

            <p>
                <span className="blue">Blue: </span>
                The letter is in the word and <b>one</b> of the letters next to it are in the right place.
            </p>
            <p>
                <span className="green">Green: </span>
                The letter is in the word and <b>both</b> of the letters next to it are in the right place.
            </p>
       
            <div className="divison"></div>

            <div className="guessRow row-2">
                <div className="guessBox bg-green"> H </div>
                <div className="guessBox bg-green"> A </div>
                <div className="guessBox bg-green"> R </div>
                <div className="guessBox bg-green"> D </div>
                <div className="guessBox bg-green"> L </div>
                <div className="guessBox bg-green"> E </div>
            </div>
        </div>
    )
}

export default Instructions;