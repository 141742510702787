import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons';

// TODO: Make clicks outside of popup close popup
function Popup(props) {
    return (
        props.showPopup &&
        <div className='popup'>
            <div className='box'>
                <div className='close'>
                    <FontAwesomeIcon icon={faClose} size='1x' onClick={() => props.setShowPopup(false)}/>
                </div>
                <div className='content'>
                    {props.content}
                </div>
            </div>
        </div>
    )
}

export default Popup;