import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';

function Share(props) {
    const { results, id } = props;

    const squareCodes = {
        white: '\u2B1C',
        yellow: '\uD83D\uDFE8',
        blue: '\uD83D\uDFE6',
        green: '\uD83D\uDFE9'
    }

    const resultColors = {
        0: 'white',
        1: 'yellow',
        2: 'blue',
        3: 'green'
    }

    function addToClipboard() {
        const link = 'https://hardle.app'
        const identifier = JSON.parse(id).id;
        const date = identifier.slice(0, -2);
        const count = identifier.slice(-1) === '0' ? '#1' : '#2';
        const title = `${link}\n${date} ${count}\n`;

        let message = title;

        results.map(word => {
            word.map(letter => {
                message = message.concat(squareCodes[resultColors[letter]]);
            });
            message = message.concat('\n');
        });

        navigator.clipboard.writeText(message);
        props.handleNotification('Copied to clipboard', 'green')
    }

    return (
        <button className="share" onClick={() => addToClipboard()}>
            <span> Share </span>
            <FontAwesomeIcon icon={faShareNodes} />
        </button>
    )
}

export default Share;